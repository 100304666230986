<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/Fitrat.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Фитрат <br />(1884 -1938 )</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Фитрат ХХ аср ўзбек адабиёти, фани ва маданиятининг йирик вакили. У
            қомусий билимга ега олим, адабиёт назариячиси, ўткир тилшунос,
            бетакрор драматург ва шоир, жанговар публицист, ношир ва журналист,
            талантли давлат ва жамоат арбоби еди. Aбдурауф Фитрат ўз таржимаи
            ҳолида ёзишича, 1884 йилда Бухорода туғилди. Фитрат – Aбдурауфнинг
            тахаллуси бўлиб, “туғма истеъдод” маъносини беради. У бошланғич
            тарбияни диний мактабда, сўнг машҳур “Мирараб” мадрасасида олди.
            Форс ва ўзбек тилларининг мукаммал билимдони бўлмиш Фитрат араб
            тилини ҳам чуқур егаллаб, шаҳарда юз бераётган ижтимоий-сиёсий
            воқеаларга, айниқса, жадидчилик ҳаракатларига қизиқиб қаради.
            Ўзининг айтишича, аввал жадидлар ҳаракатига қаршилик қилди, кейин
            унинг моҳиятини англаб йетгач, бу ҳаракатга қўшилди, ҳатто ашаддий
            тарғиботчиларидан бирига айланди. Жадидлар маслаҳати ва ёрдами билан
            бир гуруҳ ёшлар, жумладан Фитрат Истанбулга ўқишга юборилади. У
            1909–1913 йилларда Туркияда таҳсил олди. 1909 йили Истанбулда
            “Ҳиндистонда бир фаранги ила бухороли бир мударриснинг бир неча
            масалалар ҳам усули жадида хусусида қилган мунозараси” китоби
            босилиб чиқди. Китоб Бухорода ижтимоий онгнинг жонланишига катта
            туртки бўлди, ҳатто ҳукумат томонидан таъқиб қилинаётган жадид
            мактабларининг кўплаб очилишига, мактаб-таълим ишларининг
            жонланишига ёрдам берди. Фитрат юртига қайтгач, Бухоро ва
            Самарқанддаги қизғин адабий жараён, маданий, маориф ишларига ва
            сиёсий ҳаракатларга фаол аралашиб кетди. Жадидчиларнинг бир гуруҳи
            маориф-маърифат орқали халқ аҳволини яхшилашга интилишса, иккинчи
            гуруҳи давлат тизимини ислоҳ етиш, қайта қуриш орқалигина халқ ва
            юрт аҳволини ўнглаш мумкин, деган сиёсий йўлдан юришга интилди. Шу
            мақсадда 1917 йил феврал инқилобидан кейин ёш бухороликларнинг
            инқилобий партияси тузилди. Марказий Қўмита аъзолари орасида Фитрат
            саркотиб сифатида ва Файзулла Хўжайев аъзо сифатида иштирок етишди.
            Партия Aмирни ислоҳга даъват етди ва у қоғозда кўп хайрли ишлар
            қилишга ваъда берди, амалда ўз билганидан қолмади. Фитрат матбуот
            орқали ҳам ўз ғояларини тарғиб етишга интилди. Самарқандда 1917 йил
            апрелидан “Ҳуррият” газетаси чиқа бошлаган еди, аввал унга Мардонқул
            Шоҳмуҳаммадзода, кейин 27–28-сонларига Фитрат муҳаррирлик қилди.
            1917 йил Октябр тўнтариши муносабати билан шу “Ҳуррият” газетасида
            Фитратнинг “Русияда янги бало бош кўтарди – болшевик балоси!” деган
            қайди босилди (ўша газетанинг 1917 йил 49-сонида). Қўқон мухторияти
            тузилганда (1917 й. ноябр) Фитрат Самарқандда еди. Шу йердан унга
            вакил аъзо қилиб сайланди. Ўзининг “Ҳуррият” газетасида бу воқеани
            Фитрат юрақдан қувватлаб мақола еълон қилди: “Еллик йилдан бери
            езилдик, таҳқир етилдик. Қўлимиз боғланди, тилимиз кесилди. Оғзимиз
            қопланди. Йеримиз босилди. Молимиз таланди, шарафимиз йемрулди.
            Номусимиз ғасб қилинди, инсонлигимиз оёқлар остига олинди. Тўзимли
            турдик. Сабр етдик. Кучга таянган ҳар буйруққа бўйсундик. Бутун
            борлиғимизни қўлдан бердик. Ёлғиз бир фикрни бермадук, ёшунтирдук,
            имонларимизга ўраб сақладук. Бу – Туркистон мухторияти. Фитрат 1921
            йилда Бухорога келиб давлат ишларида фаол иштирок еди. Бир қанча
            масъул вазифаларда ишлаб, Бухоронинг ривожи учун қўлидан келган
            ёрдамини берди. Aммо Ф.Хўжайев бошчилигидаги Бухоро ҳукумати
            раҳбарларининг миллатнинг тараққиёти учун олиб бораётган кенг
            кўламдаги яхши ишлари Масковга ёқмади. Масков Бухоро ҳукумати
            таркиби бой, савдогарлардан иборат бўлиб қолган, деган айбни қўйиб,
            ҳукумат аъзолигидан беш кишини, жумладан Фитратни ҳам чиқаради (1923
            йил 12 июн Пленуми). Фитрат Масковга кетишга мажбур бўлади. У йерда
            Шарқ тиллари институтида илмий ходим бўлиб ишлайди (1923–1924). Шу
            йиллар унга профессорлик унвони берилди. Фитрат Масковдан қайтиб
            келгач, Бухоро, Самарқанд, Тошкент университетларида дарс берди,
            Ўзбекистон Давлат илмий-тадқиқот институтида тил ва адабиёт
            институтида, Ўзбекистон Фанлар қўмитасида ишлади. Бу йиллари у
            шеърлар, пйесалар билан бирга ниҳоятда кўп ва теран илмий асарлар
            ёзди. Aммо Фитратнинг миллатпарварлиги болшевиклар ва Шўро ҳукумати
            раҳбарларига ёқмас еди. Уни A. Қодирий, Чўлпон, Боту, У. Носирлар
            билан биргалиқда миллатчилиқда айблаш тобора авж олди. Фитрат
            Aфсуски, халқ идеали ва бахт-саодати, ҳақиқат, адолат, маориф, фан,
            маданият ва миллат тақдири учун туну кун меҳнат қилган бу улуғ зотни
            шу партия, шу Шўролар ҳукумати аксилинқилобчи, миллатчи, “халқ
            душмани” деб айблади, 1937 йил апрелда ҳибсга олди ва 1938 йил 4
            октябрда A. Қодирий, Чўлпонлар билан бирга ваҳшийларча отиб ташлади.
            Уларни отиш ҳақидаги ҳукм бир кун кейин, яъни 1938 йил 5 октябрда
            чиққан. 1957 йилда Собиқ Иттифоқ Олий судининг ҳарбий коллегияси
            1938 йил 5 октябрдаги ҳукмни бекор қилди ва Фитратни айбсиз деб
            топди. Унинг ижодий ишлари ва ижтимоий фаолияти халқни жаҳолат,
            турғунлик, ғафлатдан уйғотиш, илм-маърифатга чорлаш, еркин, озод,
            бахт-саодатли ҳаётга, биринчи навбатда миллий мустақилликка чорлашга
            қаратилган еди. У ўзбекнинг ўзлигини таниши, ота-боболари каби
            улуғвор кашфиётлар қилишга, бирлашган, кучли, қудратли давлат
            тузишга ва дунёнинг илғор маданий мамлакатларига йетиб олишга
            чақирди. Бутун ижодининг моҳияти халқнинг қалб қўзини очишга ва
            меҳнат, яратиш, ўқиш, униш, курашга даъватдан иборатдир.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
